import { Link } from "gatsby"
import * as React from "react"
import Button from "../components/button"
import Emphasis from "../components/emphasis"
import H1 from "../components/h1"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <div className="p-8 text-center">
        <H1>Page Not Found</H1>

        <Emphasis className="block py-8">
          🙁 It looks like the page you were looking for could not be found...
        </Emphasis>

        <Link to="/" className="block">
          <Button>Go Home</Button>
        </Link>
      </div>
    </Layout>
  )
}
